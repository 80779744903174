import * as authorities from '../components/shared/constants/authority.constants'

import { isSplitEnabled } from '@/components/shared/split/split'
import { QUALTRICS_NEW_TABLE } from '@/components/shared/split/split-constants'

const QualtricsPage = () => import(/* webpackChunkName: "QualtricsPage" */ '@/components/integrations/qualtrics/QualtricsPage')
const QualtricsTable = () => import(/* webpackChunkName: "QualtricsPage" */ '@/components/integrations/qualtrics/QualtricsTable.vue')
const IncentiveForm = () => import(/* webpackChunkName: "IncentiveForm" */ '@/components/integrations/qualtrics/IncentiveForm')
const IncentiveDetails = () => import(/* webpackChunkName: "IncentiveDetails" */ '@/components/integrations/qualtrics/IncentiveDetails')

export default [
  {
    path: '/qualtrics-research',
    name: 'QualtricsPage',
    component: async (goTo) => {
      const component = isSplitEnabled(QUALTRICS_NEW_TABLE) ? await QualtricsTable() : await QualtricsPage()
      goTo(component)
    },
    meta: {
      segmentName: 'Qualtrics',
      authorities: [authorities.INTEGRATIONS_ACCESS, authorities.IS_LOGGED_IN],
      pageTitle: 'integrations.qualtrics.title'
    }
  },
  {
    path: '/qualtrics-research/add',
    name: 'NewIncentive',
    component: IncentiveForm,
    meta: {
      segmentName: 'Qualtrics Create Incentive',
      authorities: [authorities.INTEGRATIONS_MANAGE, authorities.IS_LOGGED_IN],
      pageTitle: 'integrations.qualtrics.title'
    }
  },
  {
    path: '/qualtrics-research/edit/:incentiveId',
    name: 'EditIncentive',
    component: IncentiveForm,
    meta: {
      segmentName: 'Qualtrics Edit Incentive',
      authorities: [authorities.INTEGRATIONS_MANAGE, authorities.IS_LOGGED_IN],
      pageTitle: 'integrations.qualtrics.title'
    }
  },
  {
    path: '/qualtrics-research/details/:incentiveId',
    name: 'IncentiveDetails',
    component: IncentiveDetails,
    meta: {
      segmentName: 'Qualtrics View Incentive Details',
      authorities: [authorities.INTEGRATIONS_ACCESS, authorities.IS_LOGGED_IN],
      pageTitle: 'integrations.qualtrics.title'
    }
  }
]
